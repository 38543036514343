var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-group',{attrs:{"label":_vm.labelTitle,"label-for":_vm.name}},[_c('validation-provider',{staticClass:"flex-grow-1",attrs:{"name":_vm.name,"rules":_vm.validate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-select',{class:[{ 'is-invalid': errors.length > 0 || _vm.showAlert }],attrs:{"multiple":_vm.multiple,"close-on-select":!_vm.multiple,"clearable":_vm.clearable_v,"placeholder":_vm.placeholder,"name":_vm.name,"options":_vm.options,"label":_vm.label,"reduce":_vm.reduce,"disabled":_vm.disabled,"value":_vm.selected,"loading":_vm.showLoadingIcon,"taggable":_vm.taggable,"append-to-body":_vm.inBody,"calculate-position":_vm.withPopper,"no-drop":_vm.taggable,"selectable":function () { return _vm.numberOfSelect ?  _vm.selected.length < _vm.numberOfSelect : true; }},on:{"input":_vm.onChange,"search:focus":_vm.onFocus,"search:blur":_vm.onBlur},nativeOn:{"keydown":function($event){return _vm.isTextVerify.apply(null, arguments)}},scopedSlots:_vm._u([(!_vm.taggable)?{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('span',{staticClass:"icon",attrs:{"data-icon":"T"}})])]}}:null,(_vm.showSelectAll)?{key:"list-header",fn:function(){return [_c('li',{staticClass:"cursor-pointer text-white pl-3 bg-warning",on:{"click":function($event){_vm.checkAll = !_vm.checkAll; _vm.checkAllOptions()}}},[_vm._v("Select All")])]},proxy:true}:null,(_vm.noOptionsText)?{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
return [(searching)?[_vm._v(" "+_vm._s(_vm.$t('basic.no_results_found_for'))+" "),_c('em',[_vm._v(_vm._s(search))]),_vm._v(". ")]:_c('em',{staticStyle:{"opacity":"0.5"}},[_vm._v(_vm._s(_vm.noOptionsText))])]}}:null],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_vm._t("default")],2),_c('div',{staticClass:"d-flex justify-content-between"},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.showAlert)?_c('small',{staticClass:"text-danger"},[_vm._v("number of text not valid")]):_vm._e(),(_vm.taggable)?_c('small',[_vm._v("Add tag then press enter")]):_vm._e()])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }